import React from 'react';
import {withRouter} from 'react-router-dom'
import {Helmet} from "react-helmet";
import GTAG from "./SEO";

/**
 * @class
 * @classdesc This component sets scroll position to window top
 * whenever url route change is detected.
 */
class PageChange extends React.Component {

    constructor(props) {
        super(props);
        GTAG.registerErrorLogger();
    }

    /**
     * @description When app route changes check location and scroll
     * to top if path has changed
     * @param {Object} prevProps - previous properties
     */
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && !this.props.location.hash) {
            GTAG.pageView();
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('f');
            if (!myParam || myParam !== "1")
                window.scrollTo(0, 0)
        }
    }

    getMeta(path) {
        const title = 'Word Search Puzzle Maker',
            description = 'Generate word search puzzles in the browser';
        return <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta property="og:description" content={description}/>
        </Helmet>
    }

    /**
     * This component adds meta tags to a page and
     * renders any/all child components as-is
     */
    render() {
        const {pathname} = this.props.location;
        return <>{this.getMeta(pathname)}{this.props.children}</>
    }
}

export default withRouter(PageChange)
