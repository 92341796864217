/**
 * This class wraps analytics methods, meta tags and other page tagging functions
 */
class SEOAndAnalytics {

    static get trackingEnabled() {
        return (window.gtag);
    }

    static get isRobot() {
        return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent)
    }

    static get isIOS(){
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
    static get isAndroid(){
        return /(android)/i.test(navigator.userAgent);
    }

    /**
     * Fire pageview event
     */
    static pageView() {
        if (SEOAndAnalytics.trackingEnabled)
            window.gtag('config', 'UA-81731558-12', {'page_path': window.location.pathname});
    }

    /**
     * Fire analytics event
     */
    static event(action, category, label) {
        try {
            if (SEOAndAnalytics.trackingEnabled) {
                window.gtag('event', action, {
                    'event_category': category,
                    'event_label': label
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    static registerErrorLogger() {
        window.addEventListener('error', function (e) {
            var errorText = [
                e.message,
                'URL: ' + e.filename,
                'Line: ' + e.lineno + ', Column: ' + e.colno,
                'Mobile: ' + (e.error && e.error.stack ? e.error.stack : '(no stack trace)')
            ].join('\n');
            SEOAndAnalytics.event('log', 'error', errorText);
        });
    }

}

export default SEOAndAnalytics;
