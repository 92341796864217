import React from 'react';
import {Route, Switch} from 'react-router-dom'
import asyncComponent from './AsyncComponent'
import './App.css'

const WordSearch = asyncComponent(() => import('../pages/WordSearch'));
const Print =  asyncComponent(() => import('../pages/Design'));
const Home =  asyncComponent(() => import('../pages/Home'));

export default function App() {
    return <Switch>
        <Route path="/save" component={Print}/>
        <Route path="/home" component={Home}/>
        <Route component={WordSearch}/>
    </Switch>
}

