import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import PageChange from './app/PageChange';
import {BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './app/serviceWorker';

ReactDOM.render(<Router>
        <PageChange><App/></PageChange>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
